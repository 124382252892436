import React from "react"
import IndexPage from "./index"
import { graphql } from "gatsby"
const Restaurant = ({ data }) => {
  return (
    <>
      <IndexPage altData={data} altState="gelateria" />
    </>
  )
}

export default Restaurant

export const query = graphql`
  query {
    siteContents: allMarkdownRemark(
      filter: { frontmatter: { business: { ne: null } } }
      sort: { fields: frontmatter___business, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            intro_top
            intro_btm
            tagline
            about_desc
            business
            word_1
            word_2
            word_3
            menu_buttons {
              btn_title
              menu_file {
                publicURL
              }
            }
            address
            phone
            fb
            ig
            policy_file {
              publicURL
            }
          }
        }
      }
    }
    siteMeta: allMarkdownRemark(
      filter: { frontmatter: { business: { eq: null } } }
    ) {
      edges {
        node {
          frontmatter {
            meta_description
            meta_title
            meta_image {
              publicURL
            }
          }
        }
      }
    }
  }
`
